<template>
  <section>
    <h1 class="bold-header-text app-header">Payment</h1>
    <p class="header-info-text">
      Any in process or due payments will be shown below. If you were directed to this page and no
      payments are active, please contact your Account Advisor for more information.
    </p>
    <div v-show="!checkoutPage && !successPage">
      <extended-policy-review-modal ref="extendedPolicyReviewModal" />
      <b-row>
        <b-col xl="8" lg="12" style="border-right: 1px solid black">
          <b-row class="cart-bottom-spacing">
            <b-col xl="6">
              <p class="header-info-text" style="font-weight: 700">Items</p>
            </b-col>
          </b-row>
          <div v-if="this.getCartItems.length > 0">
            <b-row
              class="cart-bottom-spacing"
              v-for="cartItem in this.getCartItems"
              v-bind:key="cartItem.webItemID"
            >
              <b-col xl="1" lg="1" md="1" sm="2"
                ><img :src="cartItem.shoppingCartImageURL" class="cart-item-icon"
              /></b-col>
              <b-col xl="5" lg="4" md="4" sm="10" class="cart-item-text">{{
                cartItem.title
              }}</b-col>
              <b-col xl="2" lg="2" md="2" sm="4" class="cart-item-text">{{
                formatCurrency(cartItem.price)
              }}</b-col>
              <b-col xl="1" lg="1" md="1" sm="3" class="cart-item-text">{{
                cartItem.quantity
              }}</b-col>
              <b-col xl="2" lg="2" md="2" sm="4" class="cart-item-text">{{
                formatCurrency(cartItem.price * cartItem.amount)
              }}</b-col>
              <b-col xl="1" lg="1" md="1" sm="1" class="cart-item-text"
                ><i
                  class="fa-times cart-x-icon"
                  title="Remove From Cart"
                  @click="removeWebItem(cartItem.webItemID)"
                  v-if="cartItem.webItemID !== 6"
                  >×</i
                ></b-col
              >
            </b-row>
          </div>
          <div v-if="this.getCartItems.length < 1">
            <b-row>
              <b-col class="text-center">
                <h3>No items in the shopping cart</h3>
              </b-col>
            </b-row>
          </div>
          <!-- Contract Payments -->
          <div v-if="this.displayContractPayments">
            <p class="header-info-text" style="font-weight: 700">Agreement Payments</p>
            <b-card class="roundeddiv">
              <b-row
                class="cart-bottom-spacing"
                v-for="appID in this.applicationContractIdList"
                v-bind:key="appID"
              >
                <p>Application ID: {{ appID }}</p>
                <b-row
                  class="cart-bottom-spacing"
                  v-for="contract in applicationContractPayments[appID]"
                  v-bind:key="contract.contractPaymentDetailID"
                >
                  <b-col xs="12" style="width: 100%">
                    <input
                      title="Please check this box to acknowledge that the ACHC deposit is non-refundable."
                      type="checkbox"
                      v-model="contract.selected"
                      @change="handleSelectedPayment(contract.contractPaymentDetailID)"
                      style="margin-left: 5px"
                    />
                    Payment
                  </b-col>
                  <b-col v-bind:style="contract.pastDue ? 'color: red;' : 'color: black;'">{{
                    formatDate(contract.dateDue)
                  }}</b-col>
                  <b-col>{{ formatCurrency(contract.amountDue) }}</b-col>
                </b-row>
              </b-row>
            </b-card>
          </div>
          <!-- End Contract Payments -->
          <hr v-if="hasCouponCode()" />
          <b-row class="cart-bottom-spacing" v-if="hasCouponCode()">
            <b-col xl="1" lg="1" md="1" sm="2" class="cart-item-text"></b-col>
            <b-col xl="5" lg="4" md="4" sm="10" class="cart-item-text">{{
              this.getCartDiscountCode.description
            }}</b-col>
            <b-col xl="3" lg="3" md="3" sm="4" class="cart-item-text">{{
              this.getCartDiscountCode.code
            }}</b-col>
            <b-col xl="2" lg="2" md="2" sm="7" class="cart-item-text">{{
              this.getCartDiscountCode.amountIsPercentage
                ? this.getCartDiscountCode.amount + "%"
                : formatCurrency(this.getCartDiscountCode.amount)
            }}</b-col>
            <b-col xl="1" lg="1" md="1" sm="1" class="cart-item-text"
              ><i
                class="fa-times cart-x-icon"
                title="Remove From Cart"
                @click="removeDiscountCodeFromCart()"
                >×</i
              ></b-col
            >
          </b-row>
        </b-col>
        <b-col xl="4" lg="12">
          <p class="header-info-text" style="font-weight: 700">Educational Tools</p>
          <vueper-slides
            class="no-shadow"
            style="width: 80%; margin-left: 10%"
            :visible-slides="4"
            slide-multiple
            :gap="1"
            :slide-ratio="1 / 4"
            :dragging-distance="200"
          >
            <vueper-slide
              style="border-radius: 4px"
              v-for="(slide, i) in slides"
              :key="i"
              :image="slide.image"
              @click.native="openModal(slide.ref, slide.url)"
            >
            </vueper-slide>
          </vueper-slides>
          <p class="header-info-text" style="font-weight: 700">Totals</p>
          <b-row>
            <b-col sm="12">
              Discount Code:<br />
              <b-form-input
                type="text"
                v-model="discountCode"
                style="margin-top: 0.5rem"
                class="cart-bottom-spacing"
              />
              <button
                class="base-button"
                :disabled="!this.discountCode"
                @click="getDiscount()"
                style="width: 100%; margin-bottom: 2rem"
              >
                Apply
              </button>
            </b-col>
            <b-col sm="12">
              <b-row class="cart-bottom-spacing">
                <b-col xs="6">Subtotal:</b-col>
                <b-col xs="6" class="text-center" v-text="formatCurrency(subtotal)"></b-col>
              </b-row>
              <b-row class="cart-bottom-spacing">
                <b-col xs="6">Discount:</b-col>
                <b-col
                  xs="6"
                  class="text-center text-green"
                  v-text="formatCurrency(discount)"
                ></b-col>
              </b-row>
              <b-row class="cart-bottom-spacing">
                <b-col xs="6">Shipping:</b-col>
                <b-col xs="6" class="text-center" v-text="formatCurrency(shipping)"></b-col>
              </b-row>
              <hr />
              <b-row style="font-weight: 600; margin-bottom: 1rem">
                <b-col xs="6" class="bold">Total:</b-col>
                <b-col xs="6" class="text-center" v-text="formatCurrency(cart.total)"></b-col>
              </b-row>
              <button
                class="base-button-green"
                style="width: 100%"
                @click="switchPage(true)"
                v-if="this.selectedContracts.length > 0 || this.getCartItems.length > 0"
              >
                Checkout
              </button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-show="checkoutPage && !successPage">
      <b-row>
        <b-col xl="8" lg="12" style="border-right: 1px solid black">
          <b-row>
            <b-col sm="4">
              <p class="header-info-text" style="font-weight: 700">Billing Information</p>
            </b-col>
            <b-col sm="4"></b-col>
            <b-col sm="4">
              <b-form-select
                class="form-select"
                :options="addressOptions"
                value-field="addressID"
                text-field="addressName"
                v-model="billingAddressSelection"
                v-on:change="updateBillingAddressFromType"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col xl="6" lg="6" md="6" sm="6" style="margin-bottom: 0.5rem">
              <b-form-group label="First Name">
                <b-form-input v-model="cart.billingAddress.firstName" />
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.billingAddress.firstName.required &&
                    $v.cart.billingAddress.firstName.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col xl="6" lg="6" md="6" sm="6" style="margin-bottom: 0.5rem">
              <b-form-group label="Last Name">
                <b-form-input v-model="cart.billingAddress.lastName" />
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.billingAddress.lastName.required &&
                    $v.cart.billingAddress.lastName.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="12" style="margin-bottom: 0.5rem">
              <b-form-group label="Address">
                <vue-google-autocomplete
                  v-model="cart.billingAddress.address"
                  id="map"
                  ref="autocompleteBilling"
                  required
                  classname="form-control"
                  placeholder="Search Here..."
                  v-on:placechanged="getAddressDataBilling"
                  country="us"
                  @change="updateUserModifiledAddress"
                >
                </vue-google-autocomplete>
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.billingAddress.address.required &&
                    $v.cart.billingAddress.address.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col xl="6" lg="6" md="6" sm="12" style="margin-bottom: 0.5rem">
              <b-form-group label="City">
                <b-form-input v-model="cart.billingAddress.city" />
                <div
                  class="text-danger"
                  v-if="!$v.cart.billingAddress.city.required && $v.cart.billingAddress.city.$dirty"
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="3" md="3" sm="12" style="margin-bottom: 0.5rem">
              <b-form-group label="State">
                <b-form-select
                  class="form-select"
                  placeholder="Select State..."
                  v-model="cart.billingAddress.state"
                  :options="this.states"
                  value-field="stateCode"
                  text-field="stateName"
                ></b-form-select>
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.billingAddress.state.required && $v.cart.billingAddress.state.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="3" md="3" sm="12" style="margin-bottom: 0.5rem">
              <b-form-group label="Zip">
                <b-form-input v-model="cart.billingAddress.zip" />
                <div class="text-danger" :state="!$v.cart.billingAddress.zip.$error"></div>
                <div
                  class="text-danger"
                  v-if="!$v.cart.billingAddress.zip.required && $v.cart.billingAddress.zip.$dirty"
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="6" style="margin-bottom: 0.5rem">
              <b-form-group label="Phone">
                <b-form-input v-model="cart.billingAddress.phone" id="billing-phone" />
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.billingAddress.phone.required && $v.cart.billingAddress.phone.$dirty
                  "
                >
                  Field is required!
                </div>
                <div
                  class="text-danger"
                  :state="!$v.cart.billingAddress.phone.$error"
                  v-if="!$v.cart.billingAddress.phone.numeric"
                >
                  Numbers only
                </div>
                <div
                  class="text-danger"
                  :state="!$v.cart.billingAddress.phone.$error"
                  v-if="!$v.cart.billingAddress.phone.minLength"
                >
                  Must be 10 digits
                </div>
                <div
                  class="text-danger"
                  :state="!$v.cart.billingAddress.phone.$error"
                  v-if="!$v.cart.billingAddress.phone.maxLength"
                >
                  Must be 10 digits
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <p class="header-info-text" style="font-weight: 700">Shipping Information</p>
            </b-col>
            <b-col sm="4">
              <label style="margin-top: 1rem">
                <input v-model="useBillingForShipping" class="standard-checkbox" type="checkbox" />
                &nbsp; Same As Billing
              </label>
            </b-col>
            <b-col sm="4">
              <b-form-select
                class="form-select"
                :options="addressOptions"
                value-field="addressID"
                text-field="addressName"
                v-model="shippingAddressSelection"
                v-on:change="updateShippingAddressFromType"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row v-show="!useBillingForShipping">
            <b-col xl="6" lg="6" md="6" sm="6" style="margin-bottom: 0.5rem">
              <b-form-group label="First Name">
                <b-form-input v-model="cart.shippingAddress.firstName" />
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.shippingAddress.firstName.required &&
                    $v.cart.shippingAddress.firstName.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col xl="6" lg="6" md="6" sm="6" style="margin-bottom: 0.5rem">
              <b-form-group label="Last Name">
                <b-form-input v-model="cart.shippingAddress.lastName" />
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.shippingAddress.lastName.required &&
                    $v.cart.shippingAddress.lastName.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="12" style="margin-bottom: 0.5rem">
              <b-form-group label="Address">
                <vue-google-autocomplete
                  v-model="cart.shippingAddress.address"
                  id="map2"
                  ref="autocompleteShipping"
                  required
                  classname="form-control"
                  placeholder="Search Here..."
                  v-on:placechanged="getAddressDataShipping"
                  country="us"
                >
                </vue-google-autocomplete>
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.shippingAddress.address.required &&
                    $v.cart.shippingAddress.address.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col xl="6" lg="6" md="6" sm="12" style="margin-bottom: 0.5rem">
              <b-form-group label="City">
                <b-form-input v-model="cart.shippingAddress.city" />
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.shippingAddress.city.required && $v.cart.shippingAddress.city.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="3" md="3" sm="12" style="margin-bottom: 0.5rem">
              <b-form-group label="State">
                <b-form-select
                  class="form-select"
                  placeholder="Select State..."
                  v-model="cart.shippingAddress.state"
                  :options="states"
                  value-field="stateCode"
                  text-field="stateName"
                ></b-form-select>
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.shippingAddress.state.required && $v.cart.shippingAddress.state.$dirty
                  "
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="3" md="3" sm="12" style="margin-bottom: 0.5rem">
              <b-form-group label="Zip">
                <b-form-input v-model="cart.shippingAddress.zip" />
                <div
                  class="text-danger"
                  v-if="!$v.cart.shippingAddress.zip.required && $v.cart.shippingAddress.zip.$dirty"
                >
                  Field is required!
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="6" style="margin-bottom: 0.5rem">
              <b-form-group label="Phone">
                <b-form-input v-model="cart.shippingAddress.phone" id="shipping-phone" />
                <div
                  class="text-danger"
                  v-if="
                    !$v.cart.shippingAddress.phone.required && $v.cart.shippingAddress.phone.$dirty
                  "
                >
                  Field is required!
                </div>
                <div
                  class="text-danger"
                  :state="!$v.cart.shippingAddress.phone.$error"
                  v-if="!$v.cart.shippingAddress.phone.numeric"
                >
                  Numbers only
                </div>
                <div
                  class="text-danger"
                  :state="!$v.cart.shippingAddress.phone.$error"
                  v-if="!$v.cart.shippingAddress.phone.minLength"
                >
                  Must be 10 digits
                </div>
                <div
                  class="text-danger"
                  :state="!$v.cart.shippingAddress.phone.$error"
                  v-if="!$v.cart.shippingAddress.phone.maxLength"
                >
                  Must be 10 digits
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="4" lg="12">
          <p class="header-info-text" style="font-weight: 700">Payment Information</p>
          <TabStrip :selected="selectedTab" @select="onSelectTab" :tabs="tabs">
            <template v-slot:CreditCard>
              <div>
                <b-row>
                  <b-col xl="9" lg="12" md="12" sm="12" style="margin-bottom: 0.5rem">
                    <b-form-group label="Credit Card Number">
                      <b-form-input v-model="cart.paymentInformation.cardNumber" />
                      <div
                        class="text-danger"
                        v-if="
                          !$v.cart.paymentInformation.cardNumber.required &&
                          $v.cart.paymentInformation.cardNumber.$dirty
                        "
                      >
                        Field is required!
                      </div>
                      <div
                        class="text-danger"
                        :state="!$v.cart.paymentInformation.cardNumber.$error"
                        v-if="!$v.cart.paymentInformation.cardNumber.numeric"
                      >
                        Numbers only
                      </div>
                      <div
                        class="text-danger"
                        :state="!$v.cart.paymentInformation.cardNumber.$error"
                        v-if="!$v.cart.paymentInformation.cardNumber.minLength"
                      >
                        Must be 16 digits
                      </div>
                      <div
                        class="text-danger"
                        :state="!$v.cart.paymentInformation.cardNumber.$error"
                        v-if="!$v.cart.paymentInformation.cardNumber.maxLength"
                      >
                        Must be 16 digits
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col xl="3" lg="12" md="12" sm="12" style="margin-bottom: 0.5rem">
                    <b-form-group label="CVV">
                      <b-form-input v-model="cart.paymentInformation.cvv" />
                      <div
                        class="text-danger"
                        v-if="
                          !$v.cart.paymentInformation.cvv.required &&
                          $v.cart.paymentInformation.cvv.$dirty
                        "
                      >
                        Field is required!
                      </div>
                      <div
                        class="text-danger"
                        :state="!$v.cart.paymentInformation.cvv.$error"
                        v-if="!$v.cart.paymentInformation.cvv.numeric"
                      >
                        Numbers only
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col xl="6" lg="6" md="6" sm="6">
                    <b-form-group label="Expiration Month">
                      <b-form-select
                        class="form-select"
                        placeholder="Select Month..."
                        v-model="cart.paymentInformation.expirationMonth"
                        :options="getMonths()"
                      ></b-form-select>
                      <div
                        class="text-danger"
                        v-if="
                          !$v.cart.paymentInformation.expirationMonth.required &&
                          $v.cart.paymentInformation.expirationMonth.$dirty
                        "
                      >
                        Field is required!
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col xl="6" lg="6" md="6" sm="6">
                    <b-form-group label="Expiration Year">
                      <b-form-select
                        class="form-select"
                        placeholder="Select Year..."
                        v-model="cart.paymentInformation.expirationYear"
                        :options="getExpirationYears()"
                      ></b-form-select>
                      <div
                        class="text-danger"
                        v-if="
                          !$v.cart.paymentInformation.expirationYear.required &&
                          $v.cart.paymentInformation.expirationYear.$dirty
                        "
                      >
                        Field is required!
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-slot:Check>
              <div>
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <b-form-group label="Checking Type">
                      <b-form-select
                        class="form-select"
                        placeholder="Select Type..."
                        v-model="cart.paymentInformation.checkType"
                        :options="checkingTypes"
                      ></b-form-select>
                      <div
                        class="text-danger"
                        v-if="
                          !$v.cart.paymentInformation.checkType.required &&
                          $v.cart.paymentInformation.checkType.$dirty
                        "
                      >
                        Field is required!
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <b-form-group label="Routing Number">
                      <b-form-input v-model="cart.paymentInformation.routingNumber" />
                      <div
                        class="text-danger"
                        :state="!$v.cart.paymentInformation.routingNumber.$error"
                        v-if="!$v.cart.paymentInformation.routingNumber.numeric"
                      >
                        Numbers only
                      </div>
                      <div
                        class="text-danger"
                        v-if="
                          !$v.cart.paymentInformation.routingNumber.required &&
                          $v.cart.paymentInformation.routingNumber.$dirty
                        "
                      >
                        Field is required!
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <b-form-group label="Accounting Number">
                      <b-form-input v-model="cart.paymentInformation.accountNumber" />
                      <div
                        class="text-danger"
                        :state="!$v.cart.paymentInformation.accountNumber.$error"
                        v-if="!$v.cart.paymentInformation.accountNumber.numeric"
                      >
                        Numbers only
                      </div>
                      <div
                        class="text-danger"
                        v-if="
                          !$v.cart.paymentInformation.accountNumber.required &&
                          $v.cart.paymentInformation.accountNumber.$dirty
                        "
                      >
                        Field is required!
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </template>
          </TabStrip>
          <b-row style="margin-top: 2rem">
            <b-col sm="12">
              <b-row class="cart-bottom-spacing">
                <b-col xs="6">Subtotal:</b-col>
                <b-col xs="6" class="text-center" v-text="formatCurrency(subtotal)"></b-col>
              </b-row>
              <b-row class="cart-bottom-spacing">
                <b-col xs="6">Discount:</b-col>
                <b-col
                  xs="6"
                  class="text-center text-green"
                  v-text="formatCurrency(discount)"
                ></b-col>
              </b-row>
              <b-row class="cart-bottom-spacing">
                <b-col xs="6">Shipping:</b-col>
                <b-col xs="6" class="text-center" v-text="formatCurrency(shipping)"></b-col>
              </b-row>
              <hr />
              <b-row style="font-weight: 600; margin-bottom: 1rem">
                <b-col xs="6" class="bold">Total:</b-col>
                <b-col xs="6" class="text-center" v-text="formatCurrency(cart.total)"></b-col>
              </b-row>
              <b-row style="margin-bottom: 6px">
                <b-col xs="12" style="width: 100%"
                  >Has Acknowledged No Deposit Refund<input
                    title="Please check this box to acknowledge that the ACHC deposit is non-refundable."
                    type="checkbox"
                    v-model="noDepositRefundAcknowledged"
                    style="margin-left: 5px"
                  />
                </b-col>
                <!-- <b-col xs="1" class="text-right"></b-col> -->
              </b-row>
              <b-button
                :disabled="!this.noDepositRefundAcknowledged"
                class="base-button-green"
                style="width: 100%; margin-bottom: 1rem"
                @click="submitPayment()"
              >
                Place Order
              </b-button>
              <b-button
                class="base-button"
                style="width: 100%; margin-bottom: 1rem"
                @click="switchPage(false)"
                v-if="this.backToCartVisible"
              >
                Back to Cart
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-show="successPage">
      <b-row>
        <b-col lg="3"></b-col>
        <b-col lg="6" class="text-center">
          <h3 style="margin-bottom: 2rem">
            Thank you for your payment.<br />
            An email containing your payment information has been sent.
          </h3>
          <b-button
            class="base-button"
            style="width: 100%; margin-bottom: 1rem"
            @click="switchPage(false, true)"
            v-if="this.backToCartVisible"
          >
            Back to Cart
          </b-button>
        </b-col>
        <b-col lg="3"></b-col>
      </b-row>
    </div>
  </section>
</template>
<style>
.k-state-active {
  background-color: #e3ecf2 !important;
}
.k-link {
  color: #005175 !important;
}
</style>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import { TabStrip } from "@progress/kendo-vue-layout";
import moment from "../../../node_modules/moment/moment";
import "vueperslides/dist/vueperslides.css";
import ExtendedPolicyReviewModal from "../../components/Modals/Cart/ExtendedPolicyReviewModal.vue";
import ShoppingCartMixin from "../../mixins/ShoppingCartMixin.vue";
import CompanyAddress from "../../mixins/CompanyAddressMixin.vue";
import applicationCompanyMixin from "../../mixins/applicationCompanyMixin.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { bus } from "../../main";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { validationMixin } from "vuelidate";
import { getApplicationCompanyId } from "../../utils/customFunctions";

const { required, numeric, minLength, maxLength, requiredIf } = require("vuelidate/lib/validators");

export default {
  components: {
    VueperSlides,
    VueperSlide,
    "extended-policy-review-modal": ExtendedPolicyReviewModal,
    TabStrip,
    "vue-google-autocomplete": VueGoogleAutocomplete,
  },
  props: ["from", "onStep", "applicationCompanyID"],
  mounted() {
    bus.$on("applicationStatus", async (data) => {
      this.IsApplicationDisabled = data;
      if (this.from === "application-company-shopping" && !data) {
        // this.onStep === 5
        await this.addItemToCart(6, this.$route.params.applicationID);
        bus.$emit("updateCart");
      }
    });
    bus.$on("updateCart", () => {
      this.updateTotals();
    });
    bus.$on("switchToCheckout", () => {
      this.switchPage();
      this.removeBackToCart();
    });
  },
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    this.updateTotals();
    await this.getStates();
    await this.setCompanyAddresses();
    await this.resetContractPayments();
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  beforeDestroy() {
    bus.$off("updateCart");
    bus.$off("applicationStatus");
  },
  mixins: [ShoppingCartMixin, validationMixin, CompanyAddress, applicationCompanyMixin],
  validations: {
    cart: {
      billingAddress: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        address: {
          required,
        },
        city: {
          required,
        },
        state: {
          required,
        },
        zip: {
          required,
        },
        phone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
          numeric,
        },
      },
      shippingAddress: {
        firstName: {
          required: requiredIf(function () {
            return !this.useBillingForShipping;
          }),
        },
        lastName: {
          required: requiredIf(function () {
            return !this.useBillingForShipping;
          }),
        },
        address: {
          required: requiredIf(function () {
            return !this.useBillingForShipping;
          }),
        },
        city: {
          required: requiredIf(function () {
            return !this.useBillingForShipping;
          }),
        },
        state: {
          required: requiredIf(function () {
            return !this.useBillingForShipping;
          }),
        },
        zip: {
          required: requiredIf(function () {
            return !this.useBillingForShipping;
          }),
        },
        phone: {
          required: requiredIf(function () {
            return !this.useBillingForShipping;
          }),
          minLength: minLength(10),
          maxLength: maxLength(10),
          numeric,
        },
      },
      paymentInformation: {
        cardNumber: {
          required: requiredIf(function () {
            return this.selectedTab == 0;
          }),
          numeric,
          minLength: minLength(16),
          maxLength: maxLength(16),
        },
        cvv: {
          required: requiredIf(function () {
            return this.selectedTab == 0;
          }),
          numeric,
        },
        expirationMonth: {
          required: requiredIf(function () {
            return this.selectedTab == 0;
          }),
        },
        expirationYear: {
          required: requiredIf(function () {
            return this.selectedTab == 0;
          }),
        },
        routingNumber: {
          required: requiredIf(function () {
            return this.selectedTab == 1;
          }),
          numeric,
        },
        accountNumber: {
          required: requiredIf(function () {
            return this.selectedTab == 1;
          }),
          numeric,
        },
        checkType: {
          required: requiredIf(function () {
            return this.selectedTab == 1;
          }),
        },
      },
    },
  },
  data() {
    return {
      selectedContracts: [],
      displayContractPayments: false,
      slides: [
        {
          image: require("../../assets/img/cart/ACHCUWorkshops.png"),
          url: "https://www.achcu.com/workshops-general",
          ref: "ACHCWorkshops",
        },
        {
          image: require("../../assets/img/cart/ACHCUWorkbooks.png"),
          url: "",
          ref: "ACHCWorkbooks",
        },
        {
          image: require("../../assets/img/cart/itemEPR.jpg"),
          url: "",
          ref: "extendedPolicyReview",
        },
        {
          image: require("../../assets/img/cart/ACHCUReadinessPacket.png"),
          url: "",
          ref: "readinessPacket",
        },
        {
          image: require("../../assets/img/cart/ACHCUPnpManual.png"),
          url: "https://www.achcu.com/home",
          ref: "pnpManual",
        },
        {
          image: require("../../assets/img/cart/itemCovidPolicyPacket.png"),
          url: "",
          ref: "covidPolicyPacket",
        },
      ],
      tabs: [
        {
          title: "Credit Card",
          content: "CreditCard",
        },
        {
          title: "Check",
          content: "Check",
        },
      ],
      checkingTypes: [
        {
          text: "Personal Checking",
          value: "PC",
        },
        {
          text: "Personal Saving",
          value: "PS",
        },
        {
          text: "Business Checking",
          value: "BC",
        },
        {
          text: "Business Saving",
          value: "BS",
        },
      ],
      addressOptions: [
        {
          addressID: 1,
          addressName: "Mailing Address",
        },
        {
          addressID: 2,
          addressName: "Physical Address",
        },
        {
          addressID: 3,
          addressName: "Accounting Address",
        },
      ],
      useBillingForShipping: false,
      backToCartVisible: true,
      subtotal: 0.0,
      discount: 0.0,
      shipping: 0.0,
      total: 0.0,
      discountCode: "",
      checkoutPage: false,
      successPage: false,
      selectedTab: 0,
      billingAddressSelection: 3,
      shippingAddressSelection: 2,
      cart: {
        companyID: JSON.parse(localStorage.getItem("currentCompany")).companyID,
        userID: JSON.parse(localStorage.getItem("currentCompany")).aspNetUserID,
        depositApplicationID: 0,
        total: 0.0,
        webItems: [],
        coupon: {},
        billingAddress: {
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
        shippingAddress: {
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
        paymentInformation: {
          isCreditCard: true,
          cardNumber: "",
          cvv: "",
          expirationMonth: "",
          expirationYear: "",
          routingNumber: "",
          accountNumber: "",
          checkType: "",
        },
      },
      noDepositRefundAcknowledged: false,
      IsApplicationDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      getCartItems: "getCartItems",
      getCartDiscountCode: "getCartDiscountCode",
      getDepositApplicationID: "getDepositApplicationID",
    }),
  },
  methods: {
    ...mapMutations([
      "addWebItemToCart",
      "addCouponCodeDiscountToCart",
      "removeCouponCodeDiscountFromCart",
      "removeWebItemFromCart",
      "clearShoppingCart",
      "setInfoMessage",
    ]),
    openModal(ref, url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        if (ref == "extendedPolicyReview") {
          this.$refs.extendedPolicyReviewModal.show();
        }
      }
    },
    updateUserModifiledAddress: function (addressData) {
      // this.$refs.autocompleteBilling.update(addressData);
      this.cart.billingAddress.address = addressData;
    },
    async getDiscount() {
      await this.getCouponDiscount(this.discountCode, this.getCartItems);
    },
    formatCurrency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(amount);
    },
    formatDate(date) {
      return moment(date).format("MM/DD/yyyy");
    },
    removeDiscountCodeFromCart() {
      this.removeCouponCodeDiscountFromCart();
      this.updateTotals();
    },
    removeWebItem(webItemID) {
      this.removeWebItemFromCart(webItemID);
      if (this.hasCouponCode()) {
        this.getCouponDiscount(this.getCartDiscountCode.code, this.getCartItems, true);
      }
      this.updateTotals();
    },
    updateTotals() {
      var newSubtotal = 0.0;
      var newShipping = 0.0;
      for (var i = 0; i < this.getCartItems.length; i++) {
        newSubtotal = newSubtotal + this.getCartItems[i].price * this.getCartItems[i].quantity;
        if (this.getCartItems[i].shippingPrice) {
          newShipping = newShipping + this.getCartItems[i].shippingPrice;
        }
      }
      this.subtotal = newSubtotal;
      this.shipping = newShipping;
      var newDiscount = 0.0;
      if (this.hasCouponCode()) {
        if (this.getCartDiscountCode.amountIsPercentage) {
          newDiscount = this.getCartDiscountCode.amount * 0.01;
        } else {
          newDiscount = this.getCartDiscountCode.amount;
        }
      }
      this.discount = newDiscount;
      this.cart.total = this.subtotal + this.shipping - this.discount;
      this.cart.total = this.cart.total < 0 ? 0 : this.cart.total;

      this.cart.webItems = this.getCartItems;
      this.cart.coupon = this.getCartDiscountCode;
      this.cart.depositApplicationID = this.getDepositApplicationID;
    },
    hasCouponCode() {
      return Object.keys(this.getCartDiscountCode).length !== 0;
    },
    switchPage(fromCart, removeSuccessPage = false) {
      this.checkoutPage = !this.checkoutPage;
      if (fromCart) {
        bus.$emit("switchToPayment");
      }
      if (removeSuccessPage) {
        this.successPage = false;
      }
    },
    removeBackToCart() {
      this.backToCartVisible = false;
    },
    getExpirationYears() {
      var expirationYears = [];
      for (var i = 0; i < 7; i++) {
        expirationYears.push(moment().add(i, "years").year().toString());
      }
      return expirationYears;
    },
    getMonths() {
      return moment.months();
    },
    onSelectTab: function (e) {
      this.selectedTab = e.selected;
      this.cart.paymentInformation.isCreditCard = this.selectedTab === 0;
    },
    async submitPayment() {
      this.$v.cart.$touch();

      if (!this.$v.$invalid) {
        bus.$emit("showFullpageOverlay", {
          showLayover: true,
          message: "Please do not navigate away from this screen while the payment is processing.",
        });
        if (this.useBillingForShipping) {
          this.cart.shippingAddress = this.cart.billingAddress;
        }
        await this.submitCartPayment(this.cart).then((result) => {
          if (result.status === 200) {
            const applicationCompanyId = getApplicationCompanyId();
            if (
              this.from === "application-company-shopping" &&
              applicationCompanyId !== null &&
              applicationCompanyId != undefined
            ) {
              this.updateApplicationStep(applicationCompanyId);
            }
          }
        });
        this.clearShoppingCart();
        this.updateTotals();
        await this.resetContractPayments();
        bus.$emit("showFullpageOverlay", {
          showLayover: false,
        });
        this.successPage = true;
      }
    },
    async updateApplicationStep(applicationCompanyId) {
      const payload = {
        applicationCompanyID: applicationCompanyId,
        applicationStepID: 6,
        isComplete: true,
      };
      await this.updateCompletedSteps(payload);
    },
    getAddressDataBilling: function (addressData) {
      const {
        street_number: streetNumber = "",
        route = "",
        locality = "",
        administrative_area_level_1: stateName = "",
        postal_code: postalCode = "",
      } = addressData;
      this.cart.billingAddress.address = `${streetNumber} ${route}`;
      this.cart.billingAddress.city = locality;
      this.cart.billingAddress.state = stateName;
      this.cart.billingAddress.zip = postalCode;
      setTimeout(() => {
        this.$refs.autocompleteBilling.update(`${streetNumber} ${route}`);
      }, 500);
    },
    getAddressDataShipping: function (addressData) {
      const {
        street_number: streetNumber = "",
        route = "",
        locality = "",
        administrative_area_level_1: stateName = "",
        postal_code: postalCode = "",
      } = addressData;
      this.cart.shippingAddress.address = `${streetNumber} ${route}`;
      this.cart.shippingAddress.city = locality;
      this.cart.shippingAddress.state = stateName;
      this.cart.shippingAddress.zip = postalCode;
      setTimeout(() => {
        this.$refs.autocompleteShipping.update(`${streetNumber} ${route}`);
      }, 500);
    },
    setPhoneMask(phone) {
      if (phone === undefined) {
        return "";
      }
      var x = phone
        .toString()
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    applyMaskWhileTyping() {
      var billingValue = document.getElementById("billing-phone").value.toString();
      document.getElementById("billing-phone").value = this.setPhoneMask(billingValue);
      var shippingValue = document.getElementById("shipping-phone").value.toString();
      document.getElementById("shipping-phone").value = this.setPhoneMask(shippingValue);
    },
    async setCompanyAddresses() {
      await this.setCheckoutCompanyAddresses(this.cart.companyID);
      if (this.checkoutPhysicalAddress) {
        this.cart.shippingAddress.address = this.checkoutPhysicalAddress.addressLine1;
        this.cart.shippingAddress.city = this.checkoutPhysicalAddress.city;
        var physicalState = this.states.find((state) => {
          return state.stateID === this.checkoutPhysicalAddress.stateID;
        });
        if (physicalState) {
          this.cart.shippingAddress.state = physicalState.stateCode;
        }
        this.cart.shippingAddress.zip = this.checkoutPhysicalAddress.zip;
      }
      if (this.checkoutAccountingAddress) {
        this.cart.billingAddress.address = this.checkoutAccountingAddress.addressLine1;
        this.cart.billingAddress.city = this.checkoutAccountingAddress.city;
        var accountingState = this.states.find((state) => {
          return state.stateID === this.checkoutAccountingAddress.stateID;
        });
        if (accountingState) {
          this.cart.billingAddress.state = accountingState.stateCode;
        }
        this.cart.billingAddress.zip = this.checkoutAccountingAddress.zip;
      }
    },
    updateBillingAddressFromType(companyAddressTypeID) {
      var selectedAddress = this.getSelectedAddress(companyAddressTypeID);
      if (selectedAddress) {
        this.$refs.autocompleteBilling.update(selectedAddress.addressLine1);
        this.cart.billingAddress.address = selectedAddress.addressLine1;
        this.cart.billingAddress.city = selectedAddress.city;
        var foundState = this.states.find((state) => {
          return state.stateID === selectedAddress.stateID;
        });
        if (foundState) {
          this.cart.billingAddress.state = foundState.stateCode;
        }
        this.cart.billingAddress.zip = selectedAddress.zip;
      }
    },
    updateShippingAddressFromType(companyAddressTypeID) {
      var selectedAddress = this.getSelectedAddress(companyAddressTypeID);
      if (selectedAddress) {
        this.cart.shippingAddress.address = selectedAddress.addressLine1;
        this.cart.shippingAddress.city = selectedAddress.city;
        var foundState = this.states.find((state) => {
          return state.stateID === selectedAddress.stateID;
        });
        if (foundState) {
          this.cart.shippingAddress.state = foundState.stateCode;
        }
        this.cart.shippingAddress.zip = selectedAddress.zip;
      }
    },
    getSelectedAddress(companyAddressTypeID) {
      var selectedAddress =
        companyAddressTypeID === 3
          ? this.checkoutAccountingAddress
          : companyAddressTypeID === 2
          ? this.checkoutPhysicalAddress
          : companyAddressTypeID === 1
          ? this.checkoutMailingAddress
          : undefined;
      if (!selectedAddress) {
        this.setInfoMessage("A company address of this type could not be found");
      }
      return selectedAddress;
    },
    handleSelectedPayment(contractID) {
      var apps = this.applicationContractPayments;
      var appIds = this.applicationContractIdList;
      var selectedContracts = this.selectedContracts;
      appIds.forEach((appId) => {
        // Find the selected contract
        var contract = apps[appId].find((a) => a.contractPaymentDetailID == contractID);
        if (contract) {
          if (contract.selected) {
            selectedContracts.push(contract);
            this.subtotal += contract.amountDue;
            this.cart.total += contract.amountDue;
          } else {
            selectedContracts.splice(
              selectedContracts.findIndex(
                (c) => c.contractPaymentDetailID == contract.contractPaymentDetailID
              ),
              1
            );
            this.subtotal -= contract.amountDue;
            this.cart.total -= contract.amountDue;
          }
        }
      });
      this.selectedContracts = selectedContracts;
    },
    async resetContractPayments() {
      this.selectedContracts = [];
      await this.getApplicationContractPayments(this.cart.companyID);
      this.displayContractPayments =
        this.applicationContractIdList.length > 0 && this.$route.fullPath.includes("shopping-cart");
    },
  },
};
</script>
