<template>
  <b-modal
    id="eprModal"
    size="lg"
    ref="eprModal"
    @hide="close()"
    v-model="isVisible"
    title="Extended Policy Review:"
    :no-close-on-backdrop="true"
    @cancel="close()"
  >
    <b-row>
      <b-col lg="6">
        <p class="header-info-text">
          An industry expert will provide a complete review of your policies and procedures. You
          will have access to the results 30 days prior to your on-site survey, which will give your
          team insight into areas requiring improvement.
        </p>
      </b-col>
      <b-col lg="6" class="text-center">
        <div>
          <img :src="image" />
        </div>
      </b-col>
    </b-row>
    <template #modal-footer="">
      <b-button size="lg" variant="success" class="mr-1" @click="addEPRToCart">
        Add To Cart
      </b-button>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped></style>

<script>
import ShoppingCartMixin from "../../../mixins/ShoppingCartMixin.vue";
export default {
  mixins: [ShoppingCartMixin],

  name: "eprModal",

  data: () => ({
    isVisible: false,
    image: require("../../../assets/img/cart/SideImg_EPR.jpg"),
  }),

  async created() {},

  methods: {
    async show() {
      this.isVisible = true;
    },

    close() {
      if (this.exitFunction) {
        this.exitFunction();
      }
      this.isVisible = false;
    },
    cancel() {
      this.close();
    },
    async addEPRToCart() {
      await this.addItemToCart(20);
      this.close();
    },
  },
};
</script>
