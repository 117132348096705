<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
import { bus } from "../main";

export default {
  data() {
    return {
      applicationContractPayments: {},
      applicationContractIdList: [],
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "addWebItemToCart",
      "addCouponCodeDiscountToCart",
      "removeCouponCodeDiscountFromCart",
      "updateDepositApplicationID",
    ]),
    async getUpdatedCartItems(cartItems) {
      await axios
        .get(`${WebAPIUrl}/api/ecommerce/webitem/updated-web-items?`, cartItems)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getCartItemDiscounts(cartItems) {
      await axios
        .get(`${WebAPIUrl}/api/ecommerce/webitem/check-discounts?`, cartItems)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getCouponDiscount(discountCode, cartItems, removeCurrentIfNotFound = false) {
      await axios
        .post(`${WebAPIUrl}/api/ecommerce/coupons/${discountCode}`, cartItems)
        .then((result) => {
          this.addCouponCodeDiscountToCart(result.data);
          bus.$emit("updateCart");
          if (!removeCurrentIfNotFound) {
            this.setInfoMessage(
              "The discount code '" + result.data.code + "' was successfully applied"
            );
          }
          return result.data;
        })
        .catch((error) => {
          if (removeCurrentIfNotFound) {
            this.removeCouponCodeDiscountFromCart();
            bus.$emit("updateCart");
            this.setDangerMessage(
              "Discount code '" +
                discountCode +
                "' no longer applies to any items in your cart and has been removed"
            );
          } else {
            this.setDangerMessage(error.response.data);
          }
        });
    },
    async addItemToCart(webItemID, depositApplicationID) {
      await axios
        .get(`${WebAPIUrl}/api/ecommerce/webitem/${webItemID}/by-id`)
        .then((result) => {
          this.addWebItemToCart(result.data);
          if (depositApplicationID) {
            this.updateDepositApplicationID(depositApplicationID);
          }
          bus.$emit("updateCart");
          return result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async submitCartPayment(cart) {
      console.log(cart);
      return await axios
        .post(`${WebAPIUrl}/api/ecommerce/shopping-cart/checkout`, cart)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          this.setDangerMessage(error.response.data);
          return error;
        });
    },
    async getApplicationContractPayments(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/ecommerce/contract-payments/${companyID}`)
        .then((result) => {
          var data = result.data.reduce((apps, item) => {
            const app = apps[item.applicationID] || [];
            app.push(item);
            apps[item.applicationID] = app;
            return apps;
          }, {});

          this.applicationContractPayments = data;
          this.applicationContractIdList = Object.keys(data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
