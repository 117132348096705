<template>
  <div>
    <ShoppingCart
      :from="$route.params.companyID ? 'application-company-shopping' : ''"
    ></ShoppingCart>
  </div>
</template>

<script>
import ShoppingCart from "../../../views/app/ShoppingCart.vue";
import { bus } from "../../../main";
export default {
  components: {
    ShoppingCart,
  },
  data() {
    return {};
  },
  mounted() {
    bus.$emit("switchToCheckout");
  },
  methods: {},
};
</script>
