<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      applicationCompanyDetail: {},
      applicationCompanyDetailForOther: {},
      statusCode: null,
      companyApplicationCompletedSteps: [],
      applicationCompaniesByApplication: [],
      applicationCompanyData: {},
      applicationCompanyDetailProcessing: false,
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "changeCompanyAddress",
      "setApplicationsData",
    ]),
    async getApplicationCompanyDetails(applicationId, companyID) {
      this.applicationCompanyDetailProcessing = true;
      await axios
        .get(`${WebAPIUrl}/api/applicationcompany/${applicationId}/${companyID}`)
        .then((result) => {
          this.applicationCompanyDetail = result.data;
          this.applicationCompanyDetailProcessing = false;
        })
        .catch((error) => {
          this.setDangerMessage(error);
          this.applicationCompanyDetailProcessing = false;
        });
    },
    async getApplicationCompanyDetailsWithReturn(applicationId, companyID) {
      return await axios
        .get(`${WebAPIUrl}/api/applicationcompany/${applicationId}/${companyID}`)
        .then((result) => {
          const { applicationCompanyID } = result.data;
          return applicationCompanyID;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getCompletedApplicationSteps(applicationCompanyID) {
      await axios
        .get(`${WebAPIUrl}/api/applicationcompany/${applicationCompanyID}`)
        .then((result) => {
          this.companyApplicationCompletedSteps = result.data.steps;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getApplicationCompany(applicationCompanyID) {
      await axios
        .get(`${WebAPIUrl}/api/applicationcompany/${applicationCompanyID}`)
        .then((result) => {
          this.applicationCompanyData = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getApplicationCompanyDetailsForOthers(applicationCompanyId) {
      await axios
        .get(`${WebAPIUrl}/api/applicationcompany/${applicationCompanyId}`)
        .then((result) => {
          this.applicationCompanyDetailForOther = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async updateCompletedSteps(payload) {
      return await axios
        .put(`${WebAPIUrl}/api/applicationcompany/step`, payload)
        .then((result) => {
          if (result.status === 200) {
            return true;
          } else {
            this.setDangerMessage(result.data);
            return false;
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
          return false;
        });
    },
    async updateApplicationCompany(payload) {
      await axios
        .put(`${WebAPIUrl}/api/applicationcompany`, payload)
        .then((result) => {
          if (result.status === 200) {
            return true;
          } else {
            this.setDangerMessage(result.data);
            return false;
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
          return false;
        });
    },
    async getApplicationCompanyByApplication(applicationId) {
      await axios
        .get(`${WebAPIUrl}/api/applicationcompany/by-application/${applicationId}`)
        .then((result) => {
          this.statusCode = result.status;
          this.applicationCompaniesByApplication = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async submitApplicationCompany(payload) {
      await axios
        .post(`${WebAPIUrl}/api/applicationcompany`, payload)
        .then((result) => {
          this.statusCode = result.status;
          this.setModalInfoMessage(result.data);
        })
        .catch((error) => {
          this.statusCode = error.response.status;
          this.setModalDangerMessage(error.response.data);
        });
    },
    async deleteApplicationCompany(applicationCompanyID) {
      await axios
        .delete(`${WebAPIUrl}/api/applicationcompany/${applicationCompanyID}`)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) this.setInfoMessage(result.data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
